import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams, useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";

import { Input, Modal } from "antd";

import logo from "../../../assets/img/logo.png";
import refresh from "../../../assets/img/refresh.svg";
import useApi from "../../../hooks/useApi";
import useApp from "../../../hooks/useApp";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setAccount } from "../../../app/accountSlice";
import { setLoading } from "../../../app/loadingSlice";
import toast from "react-hot-toast";
import useViewport from "../../../hooks/useViewport";
const { Search } = Input;

function InActiveAccount(props) {
  const navigate = useNavigate();
  const { register, signMessage } = useApp();
  const { getReferrer, loginApi } = useApi();
  const { setValueToCache, getValueFromCache } = useLocalStorage();
  const dispatch = useDispatch();
  const { account } = useSelector((state) => state.account);
  const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const viewPort = useViewport();

  const showWaitingModal = () => {
    setIsWaitingModalOpen(true);
  };
  const handleWaitingModalOk = () => {
    setIsWaitingModalOpen(false);
  };
  const handleWaitingModalCancel = () => {
    setIsWaitingModalOpen(false);
  };

  const onSearch = (value) => console.log(value);

  const onRefreshButtonClicked = async () => {
    let signature = await getValueFromCache(account, "login");
    if (!signature) {
      const message = "Login";
      signature = await signMessage(message);
    }
    if (signature) {
      setValueToCache(account, "login", signature);
      dispatch(setLoading(true));
      const accountResponse = await loginApi(signature);
      if (accountResponse?.status === 1) {
        dispatch(setAccount(accountResponse.data));
        dispatch(setLoading(false));
        if (accountResponse.data.status === 1) {
          navigate("/account");
        }
      } else {
        dispatch(setLoading(false));
      }
    }
  };

  const onActiveUserButtonClicked = async () => {
    try {
      const response = await getReferrer(account?.walletId);
      if (response.status === 1) {
        const { referrerId } = response.data;
        if (referrerId === "0x0000000000000000000000000000000000000000") {
          const id = searchParams.get("ref");
          if (isValidEthereumAddress(id)) {
            await register(id);
            toast.success("Sending active transaction successfully");
            showWaitingModal();
            return;
          } else {
            toast.error(
              "Invalid referrer address. Please check with your referrer"
            );
            return;
          }
        }
        await register(referrerId);
        toast.success("Sending active transaction successfully");
        showWaitingModal();
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      //user reject transaction
      console.log(err.code);
      if (err.code == 4001 || err.code === "ACTION_REJECTED") {
        toast.error("User rejected transaction");
      }
    }
  };

  function isValidEthereumAddress(address) {
    const regex = /^(0x)?[0-9a-fA-F]{40}$/;
    return regex.test(address);
  }

  return (
    <>
      <div className="bg-[#11172b] pb-10">
        <div className="container mx-auto px-3 lg:px-16 text-center">
          <h3 className="text-primary font-semibold">USERS AREA</h3>
          <h4 className="text-3xl font-semibold mt-1">WAITING ROOM</h4>
          <span className="mt-3 block text-gray">
            Users need to activate their accounts to join the referrer's network
          </span>
        </div>
      </div>
      <main className="py-12">
        <div className="container mx-auto px-3 lg:px-16">
          {/* <div className="max-w-xl mx-auto">
            <Search
              className="outline-none w-full rounded-none"
              placeholder="Input the wallet address to search"
              allowClear
              onSearch={onSearch}
            />
          </div> */}

          <div className="my-5 p-5 border-2 border-primary bg-[url('./assets/img/bg-1.png')] bg-no-repeat bg-cover bg-top lg:p-10 lg:my-16">
            <div className="flex flex-col mt-5">
              <div className="flex justify-between">
                <span>My address</span>
                <span>
                  <span className="text-orange-400">Inactive Account</span>
                  <span className="inline-block w-3 h-3 rounded-full bg-orange-400 ml-2"></span>
                </span>
              </div>
              <span className="border-2 border-white block mt-3 py-2 px-5 min-h-[40px]">
                {account?.walletId && viewPort?.width < 600
                  ? `${account?.walletId?.substring(
                      0,
                      8
                    )}...${account?.walletId?.substring(
                      account?.walletId?.length - 8
                    )}`
                  : account?.walletId}
              </span>
            </div>
            <div className="flex flex-col mt-8">
              <span>Balance</span>
              <div className="flex flex-col gap-5 justify-between mt-3 lg:flex-row">
                <div className="border-2 border-white py-2 px-5 flex gap-3 min-w-[200px] justify-end">
                  <span>{account?.point}</span>
                  <span className="text-primary">$AIDG</span>
                </div>
                <div className="border-2 border-white flex gap-3 py-2 px-5 min-w-[200px] justify-end">
                  <span>0</span>
                  <span className="text-primary">NFT</span>
                </div>
                <div className="border-2 border-white flex gap-3 py-2 px-5 min-w-[200px] justify-end">
                  <span>0</span>
                  <span className="text-primary">NFT VIP</span>
                </div>
              </div>
            </div>
            <div className="flex justify-between mt-10">
              <div className="flex flex-col gap-5 w-full lg:items-center lg:flex-row">
                <span className="flex-shrink-0 min-w-[120px]">Referrer</span>
                <span className="block border-2 border-white py-2 px-5 w-full min-h-[40px]">
                  {isValidEthereumAddress(searchParams.get("ref"))
                    ? searchParams.get("ref")
                    : "No referrer"}
                </span>
              </div>
            </div>

            <button
              className="mt-12 bg-white mx-auto flex justify-center items-center transition-all  btn-primary text-black py-2 px-5 hover:opacity-75 "
              onClick={onActiveUserButtonClicked}>
              Active Account
            </button>
          </div>
        </div>
      </main>

      <Modal
        footer={false}
        open={isWaitingModalOpen}
        // onOk={handleWaitingModalOk}
        // onCancel={handleWaitingModalCancel}
      >
        <div className="max-w-[140px] mx-auto">
          <img src={logo} alt="logo" />
        </div>
        <div className="text-center">
          <span className="block mt-4 text-lg font-semibold">
            Keep relax! You're almost there...
          </span>

          <div className="text-left my-5">
            <span className="font-semibold">You are in queue!</span>
            <span className="block h-[5px] overflow-hidden bg-white mt-1 relative">
              <span className="absolute h-full bg-primary w-[100%] top-0 bottom-0"></span>
            </span>
          </div>
          <span>
            After sending active transaction, please wait 10 seconds and then
            click the refresh button to check account status
          </span>
          <button
            onClick={onRefreshButtonClicked}
            className="mt-8 bg-white mx-auto flex justify-center gap-2 items-center transition-all  btn-primary text-black py-2 px-5 hover:opacity-75 ">
            <span>Refresh</span>
            <span className="w-5 h-5">
              <img src={refresh} alt="" />
            </span>
            <ReactLoading
              type="spin"
              color="#000"
              height={"22px"}
              width={"22px"}
            />
          </button>
        </div>
      </Modal>
    </>
  );
}

export default InActiveAccount;
