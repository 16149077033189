import { Input } from "antd";

const { Search } = Input;

function Home() {
  const onSearch = (value) => console.log(value);

  return (
    <main className="py-16">
      <div className="container mx-auto px-3 lg:px-16">
        <div className="my-16 p-5 border-2 border-primary bg-[url('./assets/img/bg-1.png')] bg-no-repeat bg-cover bg-top lg:p-10">
          <div className="flex flex-col mt-8">
            <span>My address</span>
            <span className="border-2 border-white block mt-3 py-2 px-5">
              0xxxxxxxxxxxxxxxxxxxxx
            </span>
          </div>
          <div className="flex flex-col mt-8">
            <span>Balance</span>
            <div className="flex flex-col gap-5 justify-between mt-3 lg:flex-row">
              <div className="border-2 border-white py-2 px-5 flex gap-3 min-w-[200px] justify-end">
                <span>0</span>
                <span className="text-primary">$AIDG</span>
              </div>
              <div className="border-2 border-white flex gap-3 py-2 px-5 min-w-[200px] justify-end">
                <span>0</span>
                <span className="text-primary">NFT</span>
              </div>
              <div className="border-2 border-white flex gap-3 py-2 px-5 min-w-[200px] justify-end">
                <span>0</span>
                <span className="text-primary">NFT VIP</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
