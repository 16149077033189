import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account: {},
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccount: (state, action) => {
      state.account = { ...state.account, ...action.payload };
    },
    resetAccount: (state, action) => {
      state.account = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAccount, resetAccount } = accountSlice.actions;

export default accountSlice.reducer;
