import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _empty from "lodash/isEmpty";

import { setAccount, resetAccount } from "../../app/accountSlice";
import { setLoading } from "../../app/loadingSlice";
import { setSignature } from "../../app/signatureSlice";

import logo from "../../assets/img/logo.png";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";

import { useWeb3React } from "@web3-react/core";
import { switchNetwork } from "../../wallet";
import useApp from "../../hooks/useApp";
import useApi from "../../hooks/useApi";
import { toast } from "react-hot-toast";

function Header(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginApi } = useApi();
  const { login, logout } = useAuth();
  const { signMessage } = useApp();
  const { setValueToCache, getValueFromCache } = useLocalStorage();
  const { account, active, chainId } = useWeb3React();

  const handleLogout = () => {
    dispatch(resetAccount());
    logout();
    navigate("/");
  };

  const connectButtonClicked = async () => {
    if (active) {
      if (chainId !== Number(process.env.REACT_APP_CHAIN_ID)) {
        await switchNetwork(process.env.REACT_APP_CHAIN_ID_HEX);
      } else {
        logout();
      }
    } else {
      login();
    }
  };

  const accountEllipsis = account
    ? `${account.substring(0, 5)}...${account.substring(account.length - 4)}`
    : null;

  useEffect(() => {
    const sign = async () => {
      if (!account || !active) {
        return;
      }
      let signature = await getValueFromCache(account, "login");
      if (!signature) {
        const message = "Login";
        signature = await signMessage(message);
      }
      if (signature) {
        setValueToCache(account, "login", signature);
        dispatch(setLoading(true));
        const accountResponse = await loginApi(signature);
        if (accountResponse?.status === 1) {
          dispatch(setAccount(accountResponse.data));
          dispatch(setSignature(signature));
          dispatch(setLoading(false));
          if (accountResponse.data.status === 1) {
            navigate("/account");
          }
          toast.success("Login successful!");
        } else {
          dispatch(setLoading(false));
        }
      }
    };
    sign();
  }, [account, active, chainId]);

  return (
    <header className="bg-[#11172b] py-8">
      <div className="container mx-auto flex justify-between items-center gap-5 first-letter px-3 lg:px-16">
        <div className="max-w-[160px]">
          <img src={logo} alt="logo" />
        </div>

        <div className="flex gap-2 lg:gap-5">
          <button
            className="btn-primary transition-all border-2 border-primary hover:bg-primary py-2 px-4 lg:py-3 lg:px-8"
            onClick={connectButtonClicked}>
            {(function () {
              if (active) {
                return chainId !== Number(process.env.REACT_APP_CHAIN_ID)
                  ? "Switch network"
                  : accountEllipsis;
              } else {
                return "Connect Wallet";
              }
            })()}
          </button>
          {active && (
            <button
              className="btn-primary transition-all border-2 border-primary bg-primary py-2 px-4 lg:py-3 lg:px-8"
              onClick={handleLogout}>
              Logout
            </button>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
