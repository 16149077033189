import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";
import LoadingOverlay from "react-loading-overlay";

import Home from "./pages/Home";
import Account from "./pages/Account/Active";
import InActiveAccount from "./pages/Account/InActive";
import Header from "./components/Header";

import "./App.css";

const ProtectedRoute = ({ user, children }) => {
  if (_.isEmpty(user)) {
    return <Navigate to="/" replace />;
  }
  return children;
};

function App() {
  const { account } = useSelector((state) => state.account);
  const { loading } = useSelector((state) => state.loading);

  return (
    <div className="App overflow-hidden">
      <Header />

      <div className={`loading-wrapper ${loading ? "show-loading" : ""}`}>
        <LoadingOverlay active spinner text="Waiting..."></LoadingOverlay>
      </div>

      <Routes>
        <Route path="/" element={<InActiveAccount />} />
        <Route
          path="/account"
          element={
            <ProtectedRoute user={account}>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route path="/inactive-account/*" element={<InActiveAccount />} />
        <Route path="/inactive-account" element={<InActiveAccount />} />
      </Routes>
    </div>
  );
}

export default App;
