import { toast } from "react-hot-toast";

const useApi = () => {
  const loginApi = async (signature) => {
    const data = await postData("https://api.dogetrixchain.ai/login", {
      signature: signature
    });
    return data;
  };

  const addUser = async (walletId, refWalletId) => {
    const data = await postData("https://api.dogetrixchain.ai/add", {
      walletId: walletId,
      referrerId: refWalletId,
    });
    return data;
  };

  const getReferrer = async (walletId) => {
    try {
      const res = await fetch(
        "https://api.dogetrixchain.ai/ref?" +
        new URLSearchParams({
          walletId: walletId,
        })
      );
      const data = await res.json();
      return data;
    } catch {
      toast.error("Server can not process the request. Try again later!!");
    }
  };
  const voteApi = async (signature, voteOption) => {
    const data = await postData("https://api.dogetrixchain.ai/vote", {
      signature: signature,
      voteOption: voteOption,
    });
    return data;
  };

  const searchApi = async (walletId, q) => {
    try {
      const res = await fetch(
        "https://api.dogetrixchain.ai/search?" +
        new URLSearchParams({
          walletId: walletId,
          q: q,
        })
      );
      const data = await res.json();
      return data;
    } catch {
      toast.error("Server can not process the request. Try again later!!");
    }
  };

  async function postData(url = "", data = {}) {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
      return response.json(); // parses JSON response into native JavaScript objects
    } catch {
      toast.error("Server can not process the request. Try again later!!");
    }
  }
  return {
    loginApi,
    addUser,
    voteApi,
    searchApi,
    getReferrer
  };
};

export default useApi;
