import { useEffect, useState } from "react";
import Tree from "react-animated-tree";

import { Input, Form, Modal, Radio, Popconfirm } from "antd";

import roundActive from "../../../assets/img/round-active.png";
import roundInActive from "../../../assets/img/round-inactive.png";
import cd from "../../../assets/img/cd.png";
import tick from "../../../assets/img/tick.png";
import useApi from "../../../hooks/useApi";
import useApp from "../../../hooks/useApp";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import useViewport from "../../../hooks/useViewport";

const { Search } = Input;

function Account() {
  const { voteApi, searchApi, addUser } = useApi();
  const { signMessage, vote } = useApp();
  const { setValueToCache, getValueFromCache } = useLocalStorage();
  const [isVoteModalOpen, setIsVoteModalOpen] = useState(false);
  const [isAddWalletModalOpen, setIsAddWalletModalOpen] = useState(false);
  const [upperWallet, setUpperWallet] = useState("");
  const viewPort = useViewport();

  console.log(viewPort.width < 600);

  const { account } = useSelector((state) => state.account);
  const [tree, setTree] = useState(
    account?.tree || {
      walletId: "",
      children: [],
    }
  );

  useEffect(() => {
    // Update the 'tree' state with the new 'account' data
    setTree(account?.tree || { walletId: "", children: [] });
    setUpperWallet("");
  }, [account]);

  const [isCopy, setIsCopy] = useState(false);

  const [form] = Form.useForm();
  const [form1] = Form.useForm();

  const treeStyles = {
    color: "white",
    fill: "white",
    width: "100%",
  };

  const onSearch = (value) => {
    if (!isValidEthereumAddress(value)) {
      toast.error("Wallet address is invalid!");
      return;
    }
    searchApi(account.walletId, value).then((res) => {
      if (res.status !== 1) {
        toast.error(res.message);
        return;
      }
      toast.success("Search successfully. Please scroll down to see the tree");
      setTree(res.data.node);
    });
  };
  function isValidEthereumAddress(address) {
    const regex = /^(0x)?[0-9a-fA-F]{40}$/;
    return regex.test(address);
  }
  const formattedDate = (date) =>
    `${padZero(date.getDate())}/${padZero(
      date.getMonth() + 1
    )}/${date.getFullYear()} ${padZero(date.getHours())}:${padZero(
      date.getMinutes()
    )}:${padZero(date.getSeconds())}`;

  function padZero(number) {
    return number.toString().padStart(2, "0");
  }

  const showVoteModal = () => {
    setIsVoteModalOpen(true);
  };
  const handleVoteModalOk = () => {
    setIsVoteModalOpen(false);
  };
  const handleVoteModalCancel = () => {
    setIsVoteModalOpen(false);
  };

  const showAddWalletModal = (upperWallet) => {
    setUpperWallet(upperWallet);
    setIsAddWalletModalOpen(true);
  };
  const handleAddWalletModalOk = () => {
    setIsAddWalletModalOpen(false);
  };
  const handleAddWalletModalCancel = () => {
    setIsAddWalletModalOpen(false);
  };

  const confirmVoteModal = (e) => {
    form
      .validateFields()
      .then(async (values) => {
        let signature = await getValueFromCache(account.walletId, "vote");
        if (!signature) {
          const message = "Vote";
          signature = await signMessage(message);
        }
        if (!signature) return;
        setValueToCache(account.walletId, "vote", signature);
        const result = await voteApi(signature, values.vote);
        if (result.status === 1) {
          const data = result.data;
          try {
            await vote(
              data.transactionId,
              data.wallets,
              data.amounts,
              data.res
            );
            toast.success(
              "Vote successfully. Please wait for the transaction is verified!"
            );
          } catch (error) {
            //check if error due to user reject transaction
            if (error.code === 4001) {
              toast.error("User rejected the transaction");
            }
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((errorInfo) => {});
  };

  const cancelVoteModal = (e) => {
    console.log(e);
  };

  const confirmAddWalletModal = (e) => {
    form1
      .validateFields()
      .then(async (values) => {
        const walletId = values.wallet_address;
        if (!walletId) {
          toast.error("Please input wallet address!");
          return;
        }

        if (walletId?.length !== 42 || walletId?.substring(0, 2) !== "0x") {
          toast.error("Wallet address is invalid!");
          return;
        }
        const res = await addUser(walletId, upperWallet);
        if (res.status == 1) {
          toast.success(res.message || "Add member successfully");
          onSearch(account.walletId);
          handleAddWalletModalCancel();
        } else {
          toast.error(res.message);
        }
      })
      .catch((errorInfo) => {});
  };

  const cancelAddWalletModal = (e) => {
    console.log(e);
  };

  const renderRoundActive = (round) => {
    const result = [];
    for (let i = 0; i < 5; i++) {
      if (i < round) {
        result.push(
          <div className="round-img max-w-[20px]">
            <img src={roundActive} alt="round-active" />
          </div>
        );
      } else {
        result.push(
          <div className="round-img max-w-[20px]">
            <img src={roundInActive} alt="round-in-active" />
          </div>
        );
      }
    }
    return result.map((item) => <>{item}</>);
  };

  const renderTree = (treeData) => {
    if (treeData?.children?.length === 0) {
      return (
        <Tree
          content={
            <div className="my-5 inline-flex flex-col py-3 px-5  border-[1px] border-white tree-bg">
              <div className="inline-flex items-center justify-between gap-5 border-b border-b-white pb-2">
                <span>
                  {`${treeData?.walletId?.substring(
                    0,
                    5
                  )}...${treeData?.walletId?.substring(
                    treeData?.walletId.length - 5
                  )}`}
                </span>
                <span className="w-5">
                  <img src={tick} />
                </span>
              </div>
              <div className="flex items-center gap-5 pt-5">
                <span
                  className="cursor-pointer border-[1px] border-white py-1 px-2 text-sm"
                  onClick={() => showAddWalletModal(treeData.walletId)}>
                  {treeData.status ? "Add Member" : "Remove User"}
                </span>
                <span
                  className="cursor-pointer border-[1px] border-white py-1 px-2 text-sm"
                  onClick={() => {
                    navigator.clipboard.writeText(treeData?.walletId);
                    toast.success("Copied the wallet to clipboard");
                  }}>
                  Copy Wallet
                </span>
              </div>
            </div>
          }
        />
      );
    }

    return (
      <Tree
        content={
          <div className="my-5 inline-flex flex-col py-3 px-5  border-[1px] border-white tree-bg">
            <div className="inline-flex items-center justify-between gap-5 border-b border-b-white pb-2">
              <span>
                {`${treeData?.walletId?.substring(
                  0,
                  5
                )}...${treeData?.walletId?.substring(
                  treeData?.walletId.length - 5
                )}`}
              </span>
              <span className="w-5">
                <img src={tick} />
              </span>
            </div>
            <div className="flex items-center gap-5 pt-5">
              <span
                className="cursor-pointer border-[1px] border-white py-1 px-2 text-sm"
                onClick={() => showAddWalletModal(treeData.walletId)}>
                {treeData.status ? "Add Member" : "Remove User"}
              </span>
              <span
                className="cursor-pointer border-[1px] border-white py-1 px-2 text-sm"
                onClick={() => {
                  navigator.clipboard.writeText(treeData?.walletId);
                  toast.success("Copied the wallet to clipboard");
                }}>
                Copy Wallet
              </span>
            </div>
          </div>
        }>
        {treeData?.children.map((child) => renderTree(child))}
      </Tree>
    );
  };

  return (
    <main className="py-16">
      <div className="container mx-auto px-3 lg:px-16">
        <div className="max-w-xl mx-auto">
          <Search
            className="outline-none w-full rounded-none"
            placeholder="Input the wallet address to search"
            allowClear
            onSearch={onSearch}
          />
        </div>

        <div className="my-16 p-5 border-2 border-primary bg-[url('./assets/img/bg-1.png')] bg-no-repeat bg-cover bg-top lg:p-10">
          <div className="flex justify-end">
            <span className="text-blue-500">
              Active at {formattedDate(new Date(account?.activeAt))}
            </span>
          </div>
          <div className="flex flex-col mt-8">
            <span>My address</span>
            <span className="border-2 border-white block mt-3 py-2 px-5 min-h-[40px]">
              {account?.walletId && viewPort?.width < 600
                ? `${account?.walletId?.substring(
                    0,
                    8
                  )}...${account?.walletId?.substring(
                    account?.walletId?.length - 8
                  )}`
                : account?.walletId}
            </span>
          </div>
          <div className="flex flex-col mt-8">
            <span>Balance</span>
            <div className="flex flex-col gap-5 justify-between mt-3 lg:flex-row">
              <div className="border-2 border-white py-2 px-5 flex gap-3 min-w-[200px] justify-end">
                <span>{account?.point}</span>
                <span className="text-primary">$AIDG</span>
              </div>
              <div className="border-2 border-white flex gap-3 py-2 px-5 min-w-[200px] justify-end">
                <span>{account?.numberOfNFT}</span>
                <span className="text-primary">NFT</span>
              </div>
              <div className="border-2 border-white flex gap-3 py-2 px-5 min-w-[200px] justify-end">
                <span>{account?.numberOfVIPNFT}</span>
                <span className="text-primary">NFT VIP</span>
              </div>
            </div>
          </div>
          <button
            className="mt-10 bg-primary flex justify-center items-center transition-all  btn-primary text-white border-2 border-primary py-2 px-8 hover:bg-[#11172B]"
            onClick={showVoteModal}>
            Vote
          </button>
        </div>

        <div className="my-16 p-5 border-2 border-primary bg-[url('./assets/img/bg-2.png')] bg-no-repeat bg-cover bg-top lg:p-10">
          <div className="flex flex-col gap-5 justify-between mt-10 lg:flex-row">
            <div className="flex items-center gap-5">
              <span>Round</span>
              <div className="flex gap-3">
                {renderRoundActive(account?.round)}
              </div>
            </div>
            <div className="flex items-center justify-between gap-5">
              <span>Last vote</span>
              <span className="block border-2 border-white py-2 px-5 min-w-[220px]">
                {account.lastVoteAt
                  ? formattedDate(new Date(account?.lastVoteAt))
                  : "None"}
              </span>
            </div>
          </div>
          <div className="flex flex-col gap-5 justify-between mt-10 lg:flex-row">
            <div className="flex items-center justify-between gap-5">
              <span>Total of F1</span>
              <span className="block border-2 border-white py-2 px-5 min-w-[220px] min-h-[40px]">
                {account?.totalRef}
              </span>
            </div>
            <div className="flex items-center gap-5">
              <span>Number of voted</span>
              <span className="block border-2 border-white py-2 px-5 min-w-[220px] min-h-[40px]">
                {account?.numberOfVote}
              </span>
            </div>
          </div>
          <div className="flex justify-between mt-10">
            <div className="flex items-center gap-5 w-full">
              <span className="flex-shrink-0 min-w-[60px] lg:min-w-[120px]">
                Referrer
              </span>
              <span className="block border-2 border-white py-2 px-5 w-full">
                {viewPort?.width < 600 && account?.referrerId
                  ? `${account?.referrerId?.substring(
                      0,
                      8
                    )}...${account?.referrerId?.substring(
                      account?.referrerId?.length - 8
                    )}`
                  : "None"}
              </span>
            </div>
          </div>
          <div className="flex justify-between mt-10">
            <div className="flex items-center gap-5 w-full relative">
              <span className="flex-shrink-0 min-w-[60px] lg:min-w-[120px]">
                Referral Link
              </span>
              <span className="block border-2 border-white py-2 px-5 w-full min-h-[40px]">
                {viewPort?.width < 600
                  ? `${account?.walletId?.substring(
                      0,
                      5
                    )}...${account?.walletId?.substring(
                      account?.walletId?.length - 5
                    )}`
                  : account?.walletId}
              </span>
              <span
                className="cursor-pointer absolute right-[20px]"
                onClick={() => {
                  navigator.clipboard.writeText(account?.walletId);

                  setIsCopy(true);
                  setTimeout(() => {
                    setIsCopy(false);
                  }, 2000);
                }}>
                {isCopy ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.2em"
                    viewBox="0 0 448 512">
                    <path
                      d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                      fill="#6ECB63"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1.2em"
                    viewBox="0 0 512 512">
                    <path
                      d="M288 448H64V224h64V160H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64zm-64-96H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64z"
                      fill="#f36f2e"
                    />
                  </svg>
                )}
              </span>
            </div>
          </div>
          {/* <div className="flex items-center gap-3 mt-16">
            <div className="w-[30px] shrink-0 lg:w-[50px]">
              <img src={cd} alt="cd" />
            </div>
            <span>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor. Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor
            </span>
          </div> */}
        </div>

        <div className="my-16 p-5 border-2 max-h-[780px] overflow-auto border-primary bg-[url('./assets/img/bg-3.png')] bg-no-repeat bg-cover bg-top tree-custom custom-scroll lg:p-10">
          {tree.walletId ? (
            <Tree open style={treeStyles} className="hello">
              {renderTree(tree)}
            </Tree>
          ) : (
            <></>
          )}
        </div>

        {/* Vote Modal */}
        <Modal
          footer={false}
          open={isVoteModalOpen}
          onOk={handleVoteModalOk}
          onCancel={handleVoteModalCancel}>
          <Form
            name="validate_other"
            form={form}
            initialValues={{
              vote: "1",
            }}
            style={{
              maxWidth: 600,
            }}>
            <Form.Item name="vote" label="Number of turns">
              <Radio.Group>
                <Radio value="1">1</Radio>
                <Radio value="2">3</Radio>
                <Radio value="3">6</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="flex justify-end gap-5">
              <Popconfirm
                openClassName="hello"
                description="Are you sure you want to submit?"
                onConfirm={confirmVoteModal}
                onCancel={cancelVoteModal}
                okText="Yes"
                cancelText="No">
                <button className="btn-primary transition-all border-2 border-primary py-1.5 px-5 bg-primary text-white">
                  Submit
                </button>
              </Popconfirm>

              <button
                className="btn-primary transition-all border-2 border-primary  bg-[#fff] py-1.5 px-5"
                onClick={handleVoteModalCancel}>
                Cancel
              </button>
            </div>
          </Form>
        </Modal>

        {/* Add Wallet Modal */}
        <Modal
          footer={false}
          open={isAddWalletModalOpen}
          onOk={handleAddWalletModalOk}
          onCancel={handleAddWalletModalCancel}>
          <div className="flex justify-end gap-5">
            <span>Adding member for {upperWallet}</span>
          </div>
          <Form
            name="validate_other"
            form={form1}
            initialValues={{
              vote: "1",
            }}
            style={{
              maxWidth: 600,
            }}>
            <Form.Item label="New Member Wallet" name="wallet_address">
              <Input placeholder="Wallet address" />
            </Form.Item>

            <div className="flex justify-end gap-5">
              <Popconfirm
                openClassName="hello"
                description="Are you sure you want to submit?"
                onConfirm={confirmAddWalletModal}
                onCancel={cancelAddWalletModal}
                okText="Yes"
                cancelText="No">
                <button className="btn-primary transition-all border-2 border-primary py-1.5 px-5 bg-primary text-white">
                  Submit
                </button>
              </Popconfirm>

              <button
                className="btn-primary transition-all border-2 border-primary  bg-[#fff] py-1.5 px-5"
                onClick={handleAddWalletModalCancel}>
                Cancel
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    </main>
  );
}

export default Account;
